import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_UpdateCustomer = _resolveComponent("UpdateCustomer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_autocomplete, {
      dense: "",
      clearable: "",
      "auto-select-first": "",
      outlined: "",
      color: "primary",
      label: _ctx.frappe._('Customer'),
      modelValue: _ctx.customer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customer) = $event)),
      items: _ctx.customers,
      "item-text": "customer_name",
      "item-value": "name",
      "background-color": "white",
      "no-data-text": _ctx.__('Customer not found'),
      "hide-details": "",
      filter: $options.customFilter,
      disabled: _ctx.readonly,
      "append-icon": "mdi-plus",
      "onClick:append": $options.new_customer,
      "prepend-inner-icon": "mdi-account-edit",
      "onClick:prependInner": $options.edit_customer
    }, {
      item: _withCtx((data) => [
        _createElementVNode("template", null, [
          _createVNode(_component_v_list_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, {
                class: "primary--text subtitle-1",
                innerHTML: data.item.customer_name
              }, null, 8 /* PROPS */, ["innerHTML"]),
              (data.item.customer_name != data.item.name)
                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                    key: 0,
                    innerHTML: `ID: ${data.item.name}`
                  }, null, 8 /* PROPS */, ["innerHTML"]))
                : _createCommentVNode("v-if", true),
              (data.item.tax_id)
                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                    key: 1,
                    innerHTML: `TAX ID: ${data.item.tax_id}`
                  }, null, 8 /* PROPS */, ["innerHTML"]))
                : _createCommentVNode("v-if", true),
              (data.item.email_id)
                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                    key: 2,
                    innerHTML: `Email: ${data.item.email_id}`
                  }, null, 8 /* PROPS */, ["innerHTML"]))
                : _createCommentVNode("v-if", true),
              (data.item.mobile_no)
                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                    key: 3,
                    innerHTML: `Mobile No: ${data.item.mobile_no}`
                  }, null, 8 /* PROPS */, ["innerHTML"]))
                : _createCommentVNode("v-if", true),
              (data.item.primary_address)
                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                    key: 4,
                    innerHTML: `Primary Address: ${data.item.primary_address}`
                  }, null, 8 /* PROPS */, ["innerHTML"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "filter", "disabled", "onClick:append", "onClick:prependInner"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UpdateCustomer)
    ])
  ]))
}