import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-698ff108"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headline primary--text" }
const _hoisted_2 = {
  class: "my-0 py-0 overflow-y-auto",
  style: {"max-height":"60vh"}
}
const _hoisted_3 = ["colspan"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")
  const _component_Customer = _resolveComponent("Customer")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_date_picker = _resolveComponent("v-date-picker")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_simple_checkbox = _resolveComponent("v-simple-checkbox")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _component_v_data_table = _resolveComponent("v-data-table")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: $data.cancel_dialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.cancel_dialog) = $event)),
      "max-width": "330"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-h5" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.__("Cancel Current Invoice ?")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "error",
                  onClick: $options.cancel_invoice
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Cancel")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "warning",
                  onClick: _cache[0] || (_cache[0] = $event => ($data.cancel_dialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.__("Back")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_v_card, {
      style: {"max-height":"70vh","height":"70vh"},
      class: "cards my-0 py-0 mt-3 grey lighten-5"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          align: "center",
          class: "items px-2 py-1"
        }, {
          default: _withCtx(() => [
            ($data.pos_profile.posa_allow_sales_order)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "9",
                  class: "pb-2 pr-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Customer)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (!$data.pos_profile.posa_allow_sales_order)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "12",
                  class: "pb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Customer)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($data.pos_profile.posa_allow_sales_order)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "3",
                  class: "pb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_select, {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      color: "primary",
                      "background-color": "white",
                      items: $data.invoiceTypes,
                      label: _ctx.frappe._('Type'),
                      modelValue: $data.invoiceType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.invoiceType) = $event)),
                      disabled: $data.invoiceType == 'Return'
                    }, null, 8 /* PROPS */, ["items", "label", "modelValue", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        ($data.pos_profile.posa_use_delivery_charges)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              align: "center",
              class: "items px-2 py-1 mt-0 pt-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "8",
                  class: "pb-0 mb-0 pr-0 pt-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_autocomplete, {
                      dense: "",
                      clearable: "",
                      "auto-select-first": "",
                      outlined: "",
                      color: "primary",
                      label: _ctx.frappe._('Delivery Charges'),
                      modelValue: $data.selcted_delivery_charges,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.selcted_delivery_charges) = $event)),
                      items: $data.delivery_charges,
                      "item-text": "name",
                      "return-object": "",
                      "background-color": "white",
                      "no-data-text": _ctx.__('Charges not found'),
                      "hide-details": "",
                      filter: $options.deliveryChargesFilter,
                      disabled: _ctx.readonly,
                      onChange: _cache[4] || (_cache[4] = $event => ($options.update_delivery_charges()))
                    }, {
                      item: _withCtx((data) => [
                        _createElementVNode("template", null, [
                          _createVNode(_component_v_list_item_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, {
                                class: "primary--text subtitle-1",
                                innerHTML: data.item.name
                              }, null, 8 /* PROPS */, ["innerHTML"]),
                              _createVNode(_component_v_list_item_subtitle, {
                                innerHTML: `Rate: ${data.item.rate}`
                              }, null, 8 /* PROPS */, ["innerHTML"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "modelValue", "items", "no-data-text", "filter", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "4",
                  class: "pb-0 mb-0 pt-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      dense: "",
                      outlined: "",
                      color: "primary",
                      label: _ctx.frappe._('Delivery Charges Rate'),
                      "background-color": "white",
                      "hide-details": "",
                      value: _ctx.formtCurrency($data.delivery_charges_rate),
                      prefix: _ctx.currencySymbol($data.pos_profile.currency),
                      disabled: ""
                    }, null, 8 /* PROPS */, ["label", "value", "prefix"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($data.pos_profile.posa_allow_change_posting_date)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 1,
              align: "center",
              class: "items px-2 py-1 mt-0 pt-0"
            }, {
              default: _withCtx(() => [
                ($data.pos_profile.posa_allow_change_posting_date)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "4",
                      class: "pb-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_menu, {
                          ref: "invoice_posting_date",
                          modelValue: $data.invoice_posting_date,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.invoice_posting_date) = $event)),
                          "close-on-content-click": false,
                          transition: "scale-transition",
                          dense: ""
                        }, {
                          activator: _withCtx(({ on, attrs }) => [
                            _createVNode(_component_v_text_field, _mergeProps({
                              modelValue: $data.posting_date,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.posting_date) = $event)),
                              label: _ctx.frappe._('Posting Date'),
                              readonly: "",
                              outlined: "",
                              dense: "",
                              "background-color": "white",
                              clearable: "",
                              color: "primary",
                              "hide-details": ""
                            }, attrs, _toHandlers(on)), null, 16 /* FULL_PROPS */, ["modelValue", "label"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_date_picker, {
                              modelValue: $data.posting_date,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.posting_date) = $event)),
                              "no-title": "",
                              scrollable: "",
                              color: "primary",
                              min: 
                _ctx.frappe.datetime.add_days(_ctx.frappe.datetime.now_date(true), -7)
              ,
                              max: _ctx.frappe.datetime.add_days(_ctx.frappe.datetime.now_date(true), 7),
                              onInput: _cache[7] || (_cache[7] = $event => ($data.invoice_posting_date = false))
                            }, null, 8 /* PROPS */, ["modelValue", "min", "max"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("template", {
            onMouseover: _cache[9] || (_cache[9] = $event => (_ctx.style = 'cursor: pointer'))
          }, [
            _createVNode(_component_v_data_table, {
              headers: $data.items_headers,
              items: $data.items,
              "single-expand": $data.singleExpand,
              expanded: $data.expanded,
              "show-expand": "",
              "item-key": "posa_row_id",
              class: "elevation-1",
              "items-per-page": $data.itemsPerPage,
              "hide-default-footer": ""
            }, {
              "item.qty": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.formtFloat(item.qty)), 1 /* TEXT */)
              ]),
              "item.rate": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.currencySymbol($data.pos_profile.currency)) + " " + _toDisplayString(_ctx.formtCurrency(item.rate)), 1 /* TEXT */)
              ]),
              "item.amount": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.currencySymbol($data.pos_profile.currency)) + " " + _toDisplayString(_ctx.formtCurrency(
                  _ctx.flt(item.qty, $data.float_precision) *
                    _ctx.flt(item.rate, $data.currency_precision)
                )), 1 /* TEXT */)
              ]),
              "item.posa_is_offer": _withCtx(({ item }) => [
                _createVNode(_component_v_simple_checkbox, {
                  value: !!item.posa_is_offer || !!item.posa_is_replace,
                  disabled: ""
                }, null, 8 /* PROPS */, ["value"])
              ]),
              "expanded-item": _withCtx(({ headers, item }) => [
                _createElementVNode("td", {
                  colspan: headers.length,
                  class: "ma-0 pa-0"
                }, [
                  _createVNode(_component_v_row, { class: "ma-0 pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                            icon: "",
                            color: "error",
                            onClick: _withModifiers($event => ($options.remove_item(item)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-delete")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_col, { cols: "1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                            icon: "",
                            color: "secondary",
                            onClick: _withModifiers($event => ($options.subtract_one(item)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-minus-circle-outline")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            disabled: !!item.posa_is_offer || !!item.posa_is_replace,
                            icon: "",
                            color: "secondary",
                            onClick: _withModifiers($event => ($options.add_one(item)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-plus-circle-outline")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "onClick"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_v_row, { class: "ma-0 pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Item Code'),
                            "background-color": "white",
                            "hide-details": "",
                            modelValue: item.item_code,
                            "onUpdate:modelValue": $event => ((item.item_code) = $event),
                            disabled: ""
                          }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('QTY'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtFloat(item.qty),
                            onChange: $event => (
                        [
                          _ctx.setFormatedFloat(item, 'qty', null, false, $event),
                          $options.calc_stock_qty(item, $event),
                        ]
                      ),
                            rules: [_ctx.isNumber],
                            disabled: !!item.posa_is_offer || !!item.posa_is_replace
                          }, null, 8 /* PROPS */, ["label", "value", "onChange", "rules", "disabled"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            dense: "",
                            "background-color": "white",
                            label: _ctx.frappe._('UOM'),
                            modelValue: item.uom,
                            "onUpdate:modelValue": $event => ((item.uom) = $event),
                            items: item.item_uoms,
                            outlined: "",
                            "item-text": "uom",
                            "item-value": "uom",
                            "hide-details": "",
                            onChange: $event => ($options.calc_uom(item, $event)),
                            disabled: 
                        !!$data.invoice_doc.is_return ||
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace
                      
                          }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue", "items", "onChange", "disabled"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Rate'),
                            "background-color": "white",
                            "hide-details": "",
                            prefix: _ctx.currencySymbol($data.pos_profile.currency),
                            value: _ctx.formtCurrency(item.rate),
                            onChange: $event => (
                        [
                          _ctx.setFormatedCurrency(
                            item,
                            'rate',
                            null,
                            false,
                            $event
                          ),
                          $options.calc_prices(item, $event),
                        ]
                      ),
                            rules: [_ctx.isNumber],
                            id: "rate",
                            disabled: 
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        !!item.posa_offer_applied ||
                        !$data.pos_profile.posa_allow_user_to_edit_rate ||
                        !!$data.invoice_doc.is_return
                          ? true
                          : false
                      
                          }, null, 8 /* PROPS */, ["label", "prefix", "value", "onChange", "rules", "disabled"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Discount Percentage'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtFloat(item.discount_percentage),
                            onChange: $event => (
                        [
                          _ctx.setFormatedCurrency(
                            item,
                            'discount_percentage',
                            null,
                            true,
                            $event
                          ),
                          $options.calc_prices(item, $event),
                        ]
                      ),
                            rules: [_ctx.isNumber],
                            id: "discount_percentage",
                            disabled: 
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        item.posa_offer_applied ||
                        !$data.pos_profile.posa_allow_user_to_edit_item_discount ||
                        !!$data.invoice_doc.is_return
                          ? true
                          : false
                      ,
                            suffix: "%"
                          }, null, 8 /* PROPS */, ["label", "value", "onChange", "rules", "disabled"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Discount Amount'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtCurrency(item.discount_amount),
                            rules: [_ctx.isNumber],
                            onChange: $event => (
                        [
                          _ctx.setFormatedCurrency(
                            item,
                            'discount_amount',
                            null,
                            true,
                            $event
                          ),
                          ,
                          $options.calc_prices(item, $event),
                        ]
                      ),
                            prefix: _ctx.currencySymbol($data.pos_profile.currency),
                            id: "discount_amount",
                            disabled: 
                        !!item.posa_is_offer ||
                        !!item.posa_is_replace ||
                        !!item.posa_offer_applied ||
                        !$data.pos_profile.posa_allow_user_to_edit_item_discount ||
                        !!$data.invoice_doc.is_return
                          ? true
                          : false
                      
                          }, null, 8 /* PROPS */, ["label", "value", "rules", "onChange", "prefix", "disabled"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Price list Rate'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtCurrency(item.price_list_rate),
                            disabled: "",
                            prefix: _ctx.currencySymbol($data.pos_profile.currency)
                          }, null, 8 /* PROPS */, ["label", "value", "prefix"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Available QTY'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtFloat(item.actual_qty),
                            disabled: ""
                          }, null, 8 /* PROPS */, ["label", "value"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Group'),
                            "background-color": "white",
                            "hide-details": "",
                            modelValue: item.item_group,
                            "onUpdate:modelValue": $event => ((item.item_group) = $event),
                            disabled: ""
                          }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Stock QTY'),
                            "background-color": "white",
                            "hide-details": "",
                            value: _ctx.formtFloat(item.stock_qty),
                            disabled: ""
                          }, null, 8 /* PROPS */, ["label", "value"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            dense: "",
                            outlined: "",
                            color: "primary",
                            label: _ctx.frappe._('Stock UOM'),
                            "background-color": "white",
                            "hide-details": "",
                            modelValue: item.stock_uom,
                            "onUpdate:modelValue": $event => ((item.stock_uom) = $event),
                            disabled: ""
                          }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      (item.posa_offer_applied)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            align: "center",
                            cols: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                dense: "",
                                label: _ctx.frappe._('Offer Applied'),
                                modelValue: item.posa_offer_applied,
                                "onUpdate:modelValue": $event => ((item.posa_offer_applied) = $event),
                                readonly: "",
                                "hide-details": "",
                                class: "shrink mr-2 mt-0"
                              }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (item.has_serial_no == 1 || item.serial_no)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 1,
                            cols: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                dense: "",
                                outlined: "",
                                color: "primary",
                                label: _ctx.frappe._('Serial No QTY'),
                                "background-color": "white",
                                "hide-details": "",
                                modelValue: item.serial_no_selected_count,
                                "onUpdate:modelValue": $event => ((item.serial_no_selected_count) = $event),
                                type: "number",
                                disabled: ""
                              }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (item.has_serial_no == 1 || item.serial_no)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 2,
                            cols: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_autocomplete, {
                                modelValue: item.serial_no_selected,
                                "onUpdate:modelValue": $event => ((item.serial_no_selected) = $event),
                                items: item.serial_no_data,
                                "item-text": "serial_no",
                                outlined: "",
                                dense: "",
                                chips: "",
                                color: "primary",
                                "small-chips": "",
                                label: _ctx.frappe._('Serial No'),
                                multiple: "",
                                onChange: $event => ($options.set_serial_no(item))
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "items", "label", "onChange"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (item.has_batch_no == 1 || item.batch_no)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 3,
                            cols: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                dense: "",
                                outlined: "",
                                color: "primary",
                                label: _ctx.frappe._('Batch No. Available QTY'),
                                "background-color": "white",
                                "hide-details": "",
                                value: _ctx.formtFloat(item.actual_batch_qty),
                                disabled: ""
                              }, null, 8 /* PROPS */, ["label", "value"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (item.has_batch_no == 1 || item.batch_no)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 4,
                            cols: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                dense: "",
                                outlined: "",
                                color: "primary",
                                label: _ctx.frappe._('Batch No Expiry Date'),
                                "background-color": "white",
                                "hide-details": "",
                                modelValue: item.batch_no_expiry_date,
                                "onUpdate:modelValue": $event => ((item.batch_no_expiry_date) = $event),
                                disabled: ""
                              }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (item.has_batch_no == 1 || item.batch_no)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 5,
                            cols: "8"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_autocomplete, {
                                modelValue: item.batch_no,
                                "onUpdate:modelValue": $event => ((item.batch_no) = $event),
                                items: item.batch_no_data,
                                "item-text": "batch_no",
                                outlined: "",
                                dense: "",
                                color: "primary",
                                label: _ctx.frappe._('Batch No'),
                                onChange: $event => ($options.set_batch_qty(item, $event))
                              }, {
                                item: _withCtx((data) => [
                                  _createElementVNode("template", null, [
                                    _createVNode(_component_v_list_item_content, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, {
                                          innerHTML: data.item.batch_no
                                        }, null, 8 /* PROPS */, ["innerHTML"]),
                                        _createVNode(_component_v_list_item_subtitle, {
                                          innerHTML: 
                                `Available QTY  '${data.item.batch_qty}' - Expiry Date ${data.item.expiry_date}`
                              
                                        }, null, 8 /* PROPS */, ["innerHTML"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "items", "label", "onChange"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      (
                      $data.pos_profile.posa_allow_sales_order &&
                      $data.invoiceType == 'Order'
                    )
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 6,
                            cols: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_menu, {
                                ref: "item_delivery_date",
                                modelValue: item.item_delivery_date,
                                "onUpdate:modelValue": $event => ((item.item_delivery_date) = $event),
                                "close-on-content-click": false,
                                "return-value": item.posa_delivery_date,
                                transition: "scale-transition",
                                dense: ""
                              }, {
                                activator: _withCtx(({ on, attrs }) => [
                                  _createVNode(_component_v_text_field, _mergeProps({
                                    modelValue: item.posa_delivery_date,
                                    "onUpdate:modelValue": $event => ((item.posa_delivery_date) = $event),
                                    label: _ctx.frappe._('Delivery Date'),
                                    readonly: "",
                                    outlined: "",
                                    dense: "",
                                    clearable: "",
                                    color: "primary",
                                    "hide-details": ""
                                  }, attrs, _toHandlers(on)), null, 16 /* FULL_PROPS */, ["modelValue", "onUpdate:modelValue", "label"])
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_date_picker, {
                                    modelValue: item.posa_delivery_date,
                                    "onUpdate:modelValue": $event => ((item.posa_delivery_date) = $event),
                                    "no-title": "",
                                    scrollable: "",
                                    color: "primary",
                                    min: _ctx.frappe.datetime.now_date()
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_spacer),
                                      _createVNode(_component_v_btn, {
                                        text: "",
                                        color: "primary",
                                        onClick: $event => (item.item_delivery_date = false)
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Cancel ")
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                      _createVNode(_component_v_btn, {
                                        text: "",
                                        color: "primary",
                                        onClick: $event => (
                            [
                              _ctx.$refs.item_delivery_date.save(
                                item.posa_delivery_date
                              ),
                              $options.validate_due_date(item),
                            ]
                          )
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" OK ")
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "min"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "return-value"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true),
                      ($data.pos_profile.posa_display_additional_notes)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 7,
                            cols: "8"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                class: "pa-0",
                                outlined: "",
                                dense: "",
                                clearable: "",
                                color: "primary",
                                "auto-grow": "",
                                rows: "1",
                                label: _ctx.frappe._('Additional Notes'),
                                modelValue: item.posa_notes,
                                "onUpdate:modelValue": $event => ((item.posa_notes) = $event),
                                value: item.posa_notes
                              }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue", "value"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ], 8 /* PROPS */, _hoisted_3)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["headers", "items", "single-expand", "expanded", "items-per-page"])
          ], 32 /* NEED_HYDRATION */)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_card, { class: "cards mb-0 mt-3 py-0 grey lighten-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { "no-gutters": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "7" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  "no-gutters": "",
                  class: "pa-1 pt-9 pr-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          value: _ctx.formtFloat($options.total_qty),
                          label: _ctx.frappe._('Total Qty'),
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "accent"
                        }, null, 8 /* PROPS */, ["value", "label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (!$data.pos_profile.posa_use_percentage_discount)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "6",
                          class: "pa-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              value: _ctx.formtCurrency($data.discount_amount),
                              onChange: _cache[10] || (_cache[10] = $event => (
                  _ctx.setFormatedCurrency(
                    $data.discount_amount,
                    'discount_amount',
                    null,
                    false,
                    $event
                  )
                )),
                              rules: [_ctx.isNumber],
                              label: _ctx.frappe._('Additional Discount'),
                              ref: "discount",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "warning",
                              prefix: _ctx.currencySymbol($data.pos_profile.currency),
                              disabled: 
                  !$data.pos_profile.posa_allow_user_to_edit_additional_discount ||
                  $data.discount_percentage_offer_name
                    ? true
                    : false
                
                            }, null, 8 /* PROPS */, ["value", "rules", "label", "prefix", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($data.pos_profile.posa_use_percentage_discount)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "6",
                          class: "pa-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              value: _ctx.formtFloat($data.additional_discount_percentage),
                              onChange: _cache[11] || (_cache[11] = $event => (
                  [
                    _ctx.setFormatedFloat(
                      $data.additional_discount_percentage,
                      'additional_discount_percentage',
                      null,
                      false,
                      $event
                    ),
                    $options.update_discount_umount(),
                  ]
                )),
                              rules: [_ctx.isNumber],
                              label: _ctx.frappe._('Additional Discount %'),
                              suffix: "%",
                              ref: "percentage_discount",
                              outlined: "",
                              dense: "",
                              color: "warning",
                              "hide-details": "",
                              disabled: 
                  !$data.pos_profile.posa_allow_user_to_edit_additional_discount ||
                  $data.discount_percentage_offer_name
                    ? true
                    : false
                
                            }, null, 8 /* PROPS */, ["value", "rules", "label", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1 mt-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          value: _ctx.formtCurrency($options.total_items_discount_amount),
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          label: _ctx.frappe._('Items Discounts'),
                          outlined: "",
                          dense: "",
                          color: "warning",
                          readonly: "",
                          "hide-details": ""
                        }, null, 8 /* PROPS */, ["value", "prefix", "label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1 mt-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          value: _ctx.formtCurrency($options.subtotal),
                          prefix: _ctx.currencySymbol($data.pos_profile.currency),
                          label: _ctx.frappe._('Total'),
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "success"
                        }, null, 8 /* PROPS */, ["value", "prefix", "label"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  "no-gutters": "",
                  class: "pa-1 pt-2 pl-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          class: "pa-0",
                          color: "warning",
                          dark: "",
                          onClick: $options.get_draft_invoices
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Held")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    ($data.pos_profile.custom_allow_select_sales_order === 1)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "6",
                          class: "pa-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              block: "",
                              class: "pa-0",
                              color: "info",
                              dark: "",
                              onClick: $options.get_draft_orders
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.__("Select S.O")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          class: _normalizeClass(["pa-0", { 'disable-events': !$data.pos_profile.posa_allow_return }]),
                          color: "secondary",
                          dark: "",
                          onClick: $options.open_returns
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Return")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["class", "onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          class: "pa-0",
                          color: "error",
                          dark: "",
                          onClick: _cache[12] || (_cache[12] = $event => ($data.cancel_dialog = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Cancel")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "6",
                      class: "pa-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          class: "pa-0",
                          color: "accent",
                          dark: "",
                          onClick: $options.new_invoice
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("Save/New")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { class: "pa-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          block: "",
                          class: "pa-0",
                          color: "success",
                          onClick: $options.show_payment,
                          dark: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.__("PAY")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    ($data.pos_profile.posa_allow_print_draft_invoices)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "6",
                          class: "pa-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              block: "",
                              class: "pa-0",
                              color: "primary",
                              onClick: $options.print_draft_invoice,
                              dark: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.__("Print Draft")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}