import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Navbar = _resolveComponent("Navbar")
  const _component_v_main = _resolveComponent("v-main")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createBlock(_component_v_app, { class: "container1" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_Navbar, {
            onChangePage: _cache[0] || (_cache[0] = $event => ($options.setPage($event)))
          }),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.page), { class: "mx-4 md-4" }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}